/* eslint-disable react/prop-types */

import { useState, createContext } from 'react';

export const Context = createContext();

function GeneralContextProvider({ children }) {
  const initialData = {
    productType: null,
    subProductType: null,
    formData: {},
    leadData: {},
    titularData: {},
    vehicleData: {},
    petData: {},
    homeData: {},
    lifeData: {},
    motorcycleData: {},
    retirementData: {},
    travelData: {},
    peacData: {},
    contractorData: {},
    insuredsData: [],
    quotationId: null,
    quotationData: null,
    medicalQuestionnairesData: {},
    dependentsData: {},
    catalogues: {},
    media: {},
    formula: {},
  };

  const [contextData, setContextData] = useState(initialData);

  const resetContext = contextKey => {
    if (contextKey) {
      const newContext = contextData;
      newContext[contextKey] = initialData[contextData];
      setContextData(newContext);
    } else {
      setContextData(initialData);
    }
  };

  const handleSetContextData = data => {
    setContextData({
      ...contextData,
      ...data,
    });
  };

  return (
    <Context.Provider
      value={{
        contextData,
        setContextData: handleSetContextData,
        resetContext,
      }}
    >
      {children}
    </Context.Provider>
  );
}

export default GeneralContextProvider;
