import i18n from 'i18next';
import moment from 'moment';
import { initReactI18next } from 'react-i18next';

import es from './locales/es';
import en from './locales/en.json';

moment.locale('es', {
  months:
    'Enero_Febrero_Marzo_Abril_Mayo_Junio_Julio_Agosto_Septiembre_Octubre_Noviembre_Diciembre'.split(
      '_',
    ),
  monthsShort:
    'Enero._Feb._Mar_Abr._May_Jun_Jul._Ago_Sept._Oct._Nov._Dec.'.split('_'),
  weekdays: 'Domingo_Lunes_Martes_Miercoles_Jueves_Viernes_Sabado'.split('_'),
  weekdaysShort: 'Dom._Lun._Mar._Mier._Jue._Vier._Sab.'.split('_'),
  weekdaysMin: 'Do_Lu_Ma_Mi_Ju_Vi_Sa'.split('_'),
});

const resources = {
  en: {
    translation: en,
  },
  es: {
    translation: es(),
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: 'es', // TODO: Necesita catalogos en ingles (navigator.language || navigator.userLanguage || "en").split("-")[0],
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
