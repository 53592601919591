import React, { useEffect, Suspense } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import Loading from '../components/Loading';

const Logout = React.lazy(() => import('./Logout'));
const SaveRef = React.lazy(() => import('./SaveRef'));
const ContactRef = React.lazy(() => import('./LandingContactForm'));
const FramePage = React.lazy(() => import('./FramePage'));
const InfoEmission = React.lazy(() => import('./InfoEmission'));
const InfoQuotation = React.lazy(() => import('./InfoQuotation'));
const FramePlatform = React.lazy(() => import('./FramePlatform'));

const AppWrapper = () => {
  useEffect(() => {
    window.addEventListener('beforeunload', alertUser);
    return () => {
      window.removeEventListener('beforeunload', alertUser);
    };
  }, []);

  const alertUser = e => {
    e.preventDefault();
    e.returnValue = '';
  };

  return (
    <Router>
      <Routes>
        <Route
          path="/platform/*"
          element={
            <Suspense fallback={<Loading />}>
              <FramePlatform />
            </Suspense>
          }
        />
        <Route
          path="/logout"
          element={
            <Suspense fallback={<Loading />}>
              <Logout />
            </Suspense>
          }
        />
        <Route
          path="/ref/:kinsurerKey/*"
          element={
            <Suspense fallback={<Loading />}>
              <SaveRef />
            </Suspense>
          }
        />
        <Route
          path="/mi-proyecto3060/:kinsurerKey/*"
          element={
            <Suspense fallback={<Loading />}>
              <ContactRef />
            </Suspense>
          }
        />
        <Route
          path="/quotation/*"
          element={
            <Suspense fallback={<Loading />}>
              <InfoQuotation />
            </Suspense>
          }
        />
        <Route
          path="/emission/*"
          element={
            <Suspense fallback={<Loading />}>
              <InfoEmission />
            </Suspense>
          }
        />
        <Route
          path="/*"
          element={
            <Suspense fallback={<Loading />}>
              <FramePage />
            </Suspense>
          }
        />
      </Routes>
    </Router>
  );
};

export default AppWrapper;
