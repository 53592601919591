import navigation from './navigation.json';
import login from './login.json';
import reset from './reset.json';
import signUp from './signUp.json';
import home from './home.json';
import beKinsurer from './beKinsurer.json';
import needInsurance from './needInsurance.json';
import infoQuotation from './infoQuotation.json';
import profile from './profile.json';
import phrases from './phrases.json';
import common from './common.json';
import contactUs from './contactUs.json';
import help from './help.json';
import headers from './headers.json';
import incentives from './incentives.json';
import quotationHealth from './quotationHealth.json';
import emission from './emission.json';
import blog from './blog.json';
import quotationCar from './quotationCar.json';
import selectorHealth from './selectorHealth.json';
import selectorCar from './selectorCar.json';
import quotation from './quotation.json';
import dashboard from './dashboard.json';
import notAvailable from './notAvailable.json';
import knowKinsu from './knowKinsu.json';
import commonPlatform from './commonPlatform.json';
import coomingSoon from './coomingSoon.json';
import myQuotations from './myQuotations.json';
import notFound from './notFound.json';
import infoEmission from './infoEmission.json';
import signatures from './signatures.json';
import formula from './formula.json';
import landingApp from './landingApp.json';
import meContact from './landing3060.json';

const es = () => ({
  navigation,
  login,
  reset,
  signUp,
  home,
  beKinsurer,
  needInsurance,
  infoQuotation,
  profile,
  help,
  phrases,
  common,
  contactUs,
  headers,
  incentives,
  quotationHealth,
  emission,
  blog,
  quotationCar,
  selectorHealth,
  selectorCar,
  quotation,
  dashboard,
  notAvailable,
  knowKinsu,
  commonPlatform,
  coomingSoon,
  myQuotations,
  notFound,
  infoEmission,
  signatures,
  formula,
  landingApp,
  meContact,
});

export default es;
