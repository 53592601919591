/* eslint-disable react/prop-types */

import React, { useEffect, useState } from 'react';
import classnames from 'classnames';
import Grow from '@mui/material/Grow';
import { SpinnerDotted } from 'spinners-react';

import logo from '../../assets/images/logo100.png';
import logoWhite from '../../assets/images/logo-white.png';

import styles from './index.module.scss';

function Loading({ white, flex, transparent }) {
  const [late, setLate] = useState(false);
  const [late2, setLate2] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => setLate(true), 10000);
    const timer2 = setTimeout(() => setLate2(true), 15000);
    return () => {
      clearTimeout(timer);
      clearTimeout(timer2);
    };
  }, []);

  return (
    <div
      className={classnames(
        styles.Loading,
        white && styles.LoadingWhite,
        flex && styles.LoadingFlex,
        transparent && styles.LoadingTransparent,
      )}
      data-testid="loading-screen"
    >
      <SpinnerDotted
        size={100}
        thickness={100}
        speed={100}
        color={white ? 'rgba(69, 117, 186, 1)' : 'rgba(255, 255, 255, 1)'}
      />
      <div className={classnames(styles.Text, white && styles.TextWhite)}>
        <div>Cargando</div>
        <Grow in={late}>
          <div>Algo está tardando más de lo normal.</div>
        </Grow>
        <Grow in={late2}>
          <div>Por favor espere.</div>
        </Grow>
      </div>
      <img src={white ? logo : logoWhite} alt="kinsu logo" />
    </div>
  );
}

export default React.memo(Loading);
