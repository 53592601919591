import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#4575BA',
    },
    secondary: {
      main: '#BFFD70',
    },
    neutral: {
      main: '#DEE3E8',
      0: '#FFFFFF',
      1: '#F8F9FB',
      2: '#EEF1F6',
      3: '#E4E8EE',
      4: '#DEE3E8',
      5: '#A8B1BD',
      6: '#6A7381',
      7: '#49505A',
      8: '#353845',
      9: '#121417',
    },
    success: {
      main: '#078364',
      3: '#09A57F',
      4: '#078364',
      5: '#027357',
    },
    error: {
      main: '#AE0A0A',
      1: '#FCD2CF',
      5: '#AE0A0A',
    },
    warning: {
      main: '#E07C02',
      1: '#FEF7B9',
      3: '#FFB400',
      4: '#E07C02',
      5: '#C33E01',
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          fontSize: '14px',
          height: '40px',
          borderRadius: '11px',
          textTransform: 'none',
          boxShadow: 'none',
          fontFamily: 'Gelion, sans-serif',
          fontWeight: '300',
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          fontFamily: "'Gelion', sans-serif",
        },
        subtitle1: {
          fontSize: '1.2rem',
          fontWeight: 100,
        },
        body1: {
          fontWeight: 100,
        },
        h6: {
          fontWeight: 200,
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontSize: '0.9rem',
          minWidth: 200,
          paddingTop: 16,
          paddingBottom: 16,
          borderBottomWidth: 1,
          borderBottomColor: '#EEE',
          borderBlockStyle: 'solid',
          fontFamily: 'Gelion, sans-serif',
          fontWeight: '300',
          '&:hover': {
            backgroundColor: '#4575BA !important',
            color: 'white',
          },
          '&:first-of-type': {
            borderTopLeftRadius: 10,
            borderTopRightRadius: 10,
          },
          '&:last-child': {
            borderBottomLeftRadius: 10,
            borderBottomRightRadius: 10,
          },
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          borderRadius: 20,
        },
      },
    },
    MuiDrawer: {
      styleOverrides: {
        paperAnchorLeft: {
          borderRadius: 0,
        },
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          width: '100%',
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        notchedOutline: {
          borderColor: 'rgba(86, 90, 102, 0.1)',
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          boxShadow: '0px 4px 13px rgba(69, 117, 186, 0.14)',
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        button: {
          textDecoration: 'none',
          fontWeight: 600,
        },
      },
    },
    MuiStepContent: {
      styleOverrides: {
        root: {
          marginLeft: 25,
        },
      },
    },
    MuiStepConnector: {
      styleOverrides: {
        vertical: {
          marginLeft: 25,
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          borderRadius: 3,
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          paddingBottom: 10,
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          paddingTop: 0,
          paddingRight: 24,
          paddingLeft: 24,
          paddingBottom: 20,
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: 'none',
        },
      },
    },
  },
});

export default theme;
